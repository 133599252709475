<template>
    <v-container fluid>
        <v-dialog
            persistent

            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveDeal">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon :to="{name: 'deal'}" color="primary"  @click="closeDialogAdd" :title="$t('close')" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row class="mt-5">
                                <v-col cols="12" md="12">
                                    <v-row>
                                        <v-col class="py-0" cols="12" >
                                            <ValidationProvider ref="business_process" rules="required" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('business_process')}}</div>
                                                <v-select v-model="business_process"  :items="business_processItems"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('business_process')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          @change="getGroups();getDealStatuses();"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" :cols="$route.name === 'deal.edit' ? 6 : 12" v-if="$route.name === 'deal.edit'">
                                            <ValidationProvider ref="number" rules="required|min:1|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('number_deal')}}</div>
                                                <v-text-field v-model="number" type="text"
                                                              :error="!valid"
                                                              :label="$t('number_deal')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" :cols="$route.name === 'deal.edit' ? 6 : 12">
                                            <ValidationProvider ref="deal_status" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('deal_status')}}</div>
                                                <v-select v-model="deal_status"
                                                          :items="deal_statusItems"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('deal_status')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="uved" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('uved.self')}}</div>
                                                <v-autocomplete v-model="uved" :items="uvedItems"
                                                                :error="!valid"
                                                                :search-input.sync="uvedSearching"
                                                                item-text="name" item-value="id"
                                                                :loading="loadingUveds"
                                                                :no-data-text="uvedSearching ? $t('nothing_found_by',{'search': uvedSearching}) : $t('nothing_found_name')"
                                                                :label="$t('uved.self')"
                                                                @click:clear="uvedSearching=null;uved=null;uvedItems=[];companyItems = [];company = null;"
                                                                @click="clearUveds"
                                                                @click:append="redirectToUved(uved)"
                                                                autocomplete="off"
                                                                hide-details
                                                                :error-messages="errors"
                                                                :disabled="loadingUveds"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable>
                                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search-list" @click="setCompanyUVED(item)">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                                    </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12">
                                            <form-dialog :ref="'form_'+form.sort" v-for="form in forms" :key="form.sort" :form="form"  />
                                        </v-col>
                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="responsible" rules="required|min:1" v-slot="{ errors, valid }">
                                                <UserSelection
                                                    multiple
                                                    v-model="responsible"
                                                    id="responsible"
                                                    :label="$t('responsible')"
                                                    :error="!valid"
                                                    :valid="valid"
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    clearable
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="auditor" rules="min:1" v-slot="{ errors, valid }">
                                                <UserSelection
                                                    multiple
                                                    v-model="auditor"
                                                    id="auditor"
                                                    :label="$t('auditors')"
                                                    :error="!valid"
                                                    :valid="valid"
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    clearable
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="task" rules="min:1" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('tasks')}}</div>
                                                <v-combobox v-model="task" :items="taskItems"
                                                            :error="!valid"
                                                            :search-input.sync="taskSearching" hide-selected
                                                            item-text="title" item-value="id" :loading="loadingTasks"
                                                            :label="$t('tasks')" @click:clear="taskItems=[]"
                                                            @click="clearTasks" @input="clearEmptyTaskItems(task)"
                                                            multiple return-object autocomplete="off"
                                                            hide-details
                                                            :error-messages="errors"
                                                            :disabled="loading"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable>
                                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search_list">
                                                        <span v-html="item.number"></span>
                                                        <span class="ml-2 hidden-is-empty" v-text="item.title"></span>
                                                    </span>
                                                    </template>
                                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <v-chip v-if="item === Object(item)"
                                                                class="input_chip"
                                                                v-bind="attrs" :input-value="selected"
                                                                @click:close="parent.selectItem(item)"
                                                                :color="!item.deleted ? 'green  lighten-5' : 'red  lighten-5'"
                                                                :title="!item.deleted ? $t('active') : $t('no_active')"
                                                                link close>
                                                            <span v-html="item.title"></span>
                                                        </v-chip>
                                                    </template>
                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{ auditorSearching ? $t('nothing_found_by', {'search': auditorSearching}) : $t('nothing_found_name')}}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-combobox>
                                            </ValidationProvider>
                                        </v-col>




                                    </v-row>
                                </v-col>


                            </v-row>

                        </v-card-text>
                        <v-card-actions class="px-4 py-7">

                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";
import FormDialog from '@/components/Eav/FormDialog.vue';
import UserSelection from "@/components/Form/UserSelection.vue";

export default {
    name: 'DealForm',
    components: {
        UserSelection,
        ValidationProvider,
        ValidationObserver,
        FormDialog
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            number: null,
            deal_statusItems: [],
            deal_status: null,
            company: null,
            companyItems: [],
            companySearching: null,
            loadingCompanies: false,
            deal_type: null,
            dealTypeItems: [],
            dealTypeSearching: null,
            loadingDealTypes: false,
            responsible: null,
            responsibleItems: [],
            responsibleSearching: null,
            loadingResponsibles: false,
            auditor: null,
            auditorItems: [],
            auditorSearching: null,
            loadingAuditors: false,
            task: null,
            taskItems: [],
            taskSearching: null,
            loadingTasks: false,
            loadingTask: false,
            deal_uuid: null,
            uved: null,
            uvedItems: [],
            uvedSearching: null,
            loadingUveds: false,
            business_process: null,
            business_processItems: [],
            forms: [],
        }
    },
    computed: {
        ...mapGetters(['BusinessProcessId','listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    async mounted() {
        this.language = this.languages[this.tab]
        //this.business_process = this.BusinessProcessId;
        await this.checkCreate()
        await this.getBusinessProcess()
        await this.getDealStatuses()

    },
    watch: {
        companySearching: debounce(function (val) {
            if (val && !this.company) {
                this.getCompanies(val)
            }
        }, 500),
        responsibleSearching: debounce(function (val) {
            if (val) {
                this.getResponsibles(val)
            }
        }, 500),
        auditorSearching: debounce(function (val) {
            if (val) {
                this.getAuditors(val)
            }
        }, 500),
        taskSearching: debounce(function (val) {
            if (val) {
                this.getTasks(val)
            }
        }, 500),
        dealTypeSearching: debounce(function (val) {
            if (val) {
                this.getDealTypes(val)
            }
        }, 500),
        uvedSearching: debounce(function (val) {
            if (val && !this.uved) {
                this.getUveds(val)
            }
        }, 500),
    },
    methods: {
        async checkCreate() {
            if (this.$route.name === "deal.edit") {
                this.heading = this.$t('deal_editing')
                if (this.$route.params.id) {
                    await this.getDeal()
                    await this.getGroups()
                }
            }
            else
            {
                if (this.$route.params.task_uuid) {
                    this.getTask(this.$route.params.task_uuid)
                }
                else if (this.$route.params.uved_uuid) {
                    this.getUved(this.$route.params.uved_uuid)
                }
                this.heading = this.$t('deal_creation')
            }
        },
        async getGroups(){

            this.loading = true;
            let params = {};

            params.type_form = 'groups';
            params.entity_type = 'deal';
            params.sub_entity_type = 'business_process';
            params.sub_entity_id = this.business_process;

            if(this.deal_uuid){
                params.entity_uuid = this.deal_uuid;
            }
            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.forms = res.body.data;
                    }
                    else{
                        this.forms = [];
                    }
                })
                .catch(err => {
                    this.forms = [];
                    this.$toastr.error(this.$t('failed_to_get_list_groups'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        closeDialogAdd(){
            if(window.history.length > 2){
                this.$router.back()
            }
            else{
                this.$router.push({
                    name: 'deal',
                })
            }
        },

        clearCompanies() {
            if (!this.company) {
                this.companyItems = []
            }
        },
        redirectToCompany(val) {
            if (val) {
                this.$router.push({
                    name: 'company.profile',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getCompanies(str) {
            if (str) {
                this.loadingCompanies = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.company = str
                }
                await this.$http
                    .get("admin/company", {
                        params: params,
                    })
                    .then(res => {
                        this.companyItems = res.body.data
                    })
                    .catch(err => {
                        this.companyItems = []
                    })
                    .finally(end => {
                        this.loadingCompanies = false
                    })
            }
        },
        async getBusinessProcess() {
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/business_process`, {
                    params: params,
                })
                .then(res => {
                    this.business_processItems = res.body.data
                    //  this.business_processItems.push({'id':0,'name':this.$t('all_deals')})
                })
                .catch(err => {
                    this.business_processItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_business_processes'))
                });
        },
        async getDealTypes(str) {
            if (str) {
                this.loadingDealTypes = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.deal_type = str
                }
                await this.$http
                    .get("admin/deal_type", {
                        params: params,
                    })
                    .then(res => {
                        this.dealTypeItems = res.body.data
                    })
                    .catch(err => {
                        this.dealTypeItems = []
                    })
                    .finally(end => {
                        this.loadingDealTypes = false
                    })
            }
        },
        clearDealTypes() {
            if (!this.deal_type) {
                this.dealTypeItems = []
            }
        },

        setCompanyUVED(item){
            if(item.company && item.company.id){
                this.companyItems = [item.company];
                this.company = item.company.id;
            }
            else {
                this.companyItems = []
                this.company = null
            }
        },
        clearUveds() {
            if (!this.uved) {
                this.uvedItems = []
            }
            this.companyItems = []
            this.company = null
        },
        redirectToUved(val) {
            if (val) {
                this.$router.push({
                    name: 'uved.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getUveds(str) {
            if (str) {
                this.loadingUveds = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.uved = str
                }
                await this.$http
                    .get("admin/uved", {
                        params: params,
                    })
                    .then(res => {
                        this.uvedItems = res.body.data
                    })
                    .catch(err => {
                        this.uvedItems = []
                    })
                    .finally(end => {
                        this.loadingUveds = false
                    })
            }
        },

        clearResponsibles() {
            if (!this.responsible) {
                this.responsibleItems = []
            }
        },
        clearEmptyResponsibleItems(items) {
            if (items && items.length > 0) {
                this.responsible = items.filter(item => typeof item === 'object')
            }
        },
        async getResponsibles(str) {
            if (str) {
                this.loadingResponsibles = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.responsibleItems = res.body.data
                    })
                    .catch(err => {
                        this.responsibleItems = []
                    })
                    .finally(end => {
                        this.loadingResponsibles = false
                    })
            }
        },

        clearAuditors() {
            if (!this.auditor) {
                this.auditorItems = []
            }
        },
        clearEmptyAuditorItems(items) {
            if (items && items.length > 0) {
                this.auditor = items.filter(item => typeof item === 'object')
            }
        },
        async getAuditors(str) {
            if (str) {
                this.loadingAuditors = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.auditorItems = res.body.data
                    })
                    .catch(err => {
                        this.auditorItems = []
                    })
                    .finally(end => {
                        this.loadingAuditors = false
                    })
            }
        },

        clearTasks() {
            if (!this.task) {
                this.taskItems = []
            }
        },
        clearEmptyTaskItems(items) {
            if (items && items.length > 0) {
                this.task = items.filter(item => typeof item === 'object')
            }
        },
        async getTasks(str) {
            if (str) {
                this.loadingTasks = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/task", {
                        params: params,
                    })
                    .then(res => {
                        this.taskItems = res.body.data
                    })
                    .catch(err => {
                        this.taskItems = []
                    })
                    .finally(end => {
                        this.loadingTasks = false
                    })
            }
        },

        async getDealStatuses() {
            if(this.business_process){
                this.loading = true
                let params = {}
                params.business_process = this.business_process
                await this.$http
                    .get("admin/deal_status", {
                        params: params,
                    })
                    .then(res => {
                        this.deal_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.deal_statusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }

        },
        async getDeal() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/deal/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.deal_uuid = res.body.data.uuid
                    this.number = res.body.data.number
                    this.deal_status = res.body.data.deal_status.id
                    this.business_process = res.body.data.business_process.id
                    this.responsible = res.body.data.responsibles
                    this.auditor = res.body.data.auditors
                    this.task = res.body.data.tasks
                    this.company = res.body.data.company.id
                    this.companyItems = [res.body.data.company]
                    this.uved = res.body.data.uved.id
                    this.uvedItems = [res.body.data.uved]


                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_deal'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveDeal() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.number) {
                formData.append('number', this.number)
            }

            this.forms.reduce((acc, item) => {
                const fields = item.fields;
                const group_id = item.id
                for (let fieldName in fields) {
                    if (Object.hasOwn(fields, fieldName)) {
                        formData.append(`fields[${group_id}][${fieldName}]`,fields[fieldName].default)
                    }
                }
            }, []);

            if (this.company) {
                if (this.company.id) {
                    formData.append('company', this.company.id)
                } else {
                    formData.append('company', this.company)
                }

            } if (this.business_process) {
                if (this.business_process.id) {
                    formData.append('business_process', this.business_process.id)
                } else {
                    formData.append('business_process', this.business_process)
                }
            }

            if (this.deal_status) {
                if (this.deal_status.id) {
                    formData.append('deal_status', this.deal_status.id)
                } else {
                    formData.append('deal_status', this.deal_status)
                }
            }

            if (this.uved) {
                if (this.uved.id) {
                    formData.append('uved', this.uved.id)
                } else {
                    formData.append('uved', this.uved)
                }
            }

            if (this.responsible && this.responsible.length > 0) {
                for (let i in this.responsible) {
                    if (this.responsible[i].id !== undefined && this.responsible[i].id > 0) {
                        formData.append(`responsibles[${i}]`, this.responsible[i].id)
                    }
                }
            }

            if (this.auditor && this.auditor.length > 0) {
                for (let i in this.auditor) {
                    if (this.auditor[i].id !== undefined && this.auditor[i].id > 0) {
                        formData.append(`auditors[${i}]`, this.auditor[i].id)
                    }
                }
            }

            if (this.task && this.task.length > 0) {
                for (let i in this.task) {
                    if (this.task[i].id !== undefined && this.task[i].id > 0) {
                        formData.append(`tasks[${i}]`, this.task[i].id)
                    }
                }
            }



            if (this.$route.params.id && this.deal_uuid ) {
                await this.$http
                    .put(`admin/deal/${this.deal_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.$router.push({
                            name: 'deal',
                        })
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/deal', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.$router.push({
                                name: 'deal.show',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        }
                        else {
                            this.$router.push({
                                name: 'deal'
                            })
                        }
                        this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },

        async getTask(task_uuid) {
            if (task_uuid) {
                this.loadingTask = true
                await this.$http
                    .get("admin/task/"+task_uuid)
                    .then(res => {
                        this.task = [res.body.data];
                        this.taskItems = [res.body.data]
                        this.company = res.body.data.company.id
                        this.companyItems = [res.body.data.company]
                        this.responsible = res.body.data.responsibles
                        this.auditor = res.body.data.auditors
                        this.uved = res.body.data.uved.id
                        this.uvedItems = [res.body.data.uved]
                    })
                    .catch(err => {
                        this.taskItems = []
                        this.companyItems = []
                        this.responsible = []
                        this.companyItems = []
                        this.uvedItems = []
                    })
                    .finally(end => {
                        this.loadingTask = false
                    })
            }
        },
        async getUved(uved_uuid) {
            if (uved_uuid) {
                this.loadingUveds = true
                await this.$http
                    .get("admin/uved/"+uved_uuid)
                    .then(res => {
                        this.uved = res.body.data.id;
                        this.uvedItems = [res.body.data]
                        this.company = res.body.data.company.id;
                        this.companyItems = [res.body.data.company]

                    })
                    .catch(err => {
                        this.uvedItems = []
                        this.companyItems = []
                    })
                    .finally(end => {
                        this.loadingUveds = false
                    })
            }
        },

    }
}
</script>

<style scoped>
.v-card--link:before {
    background: none;
}

>>>.heightTable td {
    height: 50px !important;
}

.deal_number{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
    color: #777777;
}
.text-label{
    display: block;
    color: #a9a9a9;
    font-size: 10px;
}

.wrapper-progressBar {
    width: 100%
}

.progressBar {
}

.progressBar li {
    list-style-type: none;
    float: left;
    width: 33%;
    position: relative;
    text-align: center;
}

.progressBar li:before {
    content: " ";
    line-height: 10px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: white;

}

.progressBar li:after {
    content: "";
    position: absolute;
    width: 90%;
    height: 4px;
    background-color: #ddd;
    top: 3px;
    left: -45%;

}

.progressBar li:first-child:after {
    content: none;

}

.progressBar li.active {
    color: #265898;

}

.progressBar li.active:before {
    border-color: #265898;
    background-color: #265898;

}

.progressBar .active:after {
    background-color: #265898;
}

.color_black{
    color: #000;
}
.color_dodgerblue{
    color: #265898;
}

</style>
